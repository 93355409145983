import { useState, useEffect} from "react";
import About from "./About";
import Resume from "./Resume";
import AnimateHeight  from 'react-animate-height';



function Console(){
    const [input, setInput] = useState("");
    const [height, setHeight] = useState("auto");
    const [consoleList, setConsoleList] = useState([getFirstMessage()]);
    const consoleMessage = "unknownUser@ColeMonpas.com:";
    const consoleDirectory = "/directory$ ";
    const validCommandsList = ["clear", "help", "pages", "open", "banner"];

    var interval = 10, isTimerPaused = true;
    const timer = setInterval(() => {
        if (isTimerPaused === false){
            console.log("In interval")
            updateScrollArea();
        }
    }, interval);

    useEffect(() => {

        const consoleContentID = document.getElementById("ConsoleContentID")
    
        setTimeout(() => {
            setHeight(consoleContentID.offsetHeight);
        }, interval);

        return ()=>{
            clearInterval(timer);
        }
    });


    function startInterval(){
        isTimerPaused = false;
        console.log("started " + timer);
    }

    function stopInterval(){
        isTimerPaused = true;
        console.log( "cleared " + timer);
    }

    function updateScrollArea(){
        let consoleContentElement = document.getElementById("ConsoleID");
        if (consoleContentElement !== null){
            consoleContentElement.scrollTop = consoleContentElement.scrollHeight;
        }
    }

    function getFirstMessage(){
        return (
            <div key={0}>
                {getBanner()}
            </div>
        )
    }

    // returns the banner html
    function getBanner(){
        return (
            <div className="FirstMessage" key={Math.random()}>
                <div className="blockThis typewriter">
                    <span>
                        <div> ██████╗ ██████╗ ██╗     ███████╗███████╗    ████████╗███████╗██████╗ ███╗   ███╗██╗███╗   ██╗ █████╗ ██╗   </div>
                        <div>██╔════╝██╔═══██╗██║     ██╔════╝██╔════╝    ╚══██╔══╝██╔════╝██╔══██╗████╗ ████║██║████╗  ██║██╔══██╗██║  </div>
                        <div>██║     ██║   ██║██║     █████╗  ███████╗       ██║   █████╗  ██████╔╝██╔████╔██║██║██╔██╗ ██║███████║██║   </div>
                        <div>██║     ██║   ██║██║     ██╔══╝  ╚════██║       ██║   ██╔══╝  ██╔══██╗██║╚██╔╝██║██║██║╚██╗██║██╔══██║██║    </div>
                        <div>╚██████╗╚██████╔╝███████╗███████╗███████║       ██║   ███████╗██║  ██║██║ ╚═╝ ██║██║██║ ╚████║██║  ██║███████╗</div>
                        <div> ╚═════╝ ╚═════╝ ╚══════╝╚══════╝╚══════╝       ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝ v1.6.1</div>
                        <br></br>
                        <div className="FlexThis typewriter">
                            <div>Type '</div>
                            <div className="CommandColor">help</div>
                            <div>' to see a list of available commands.</div>
                        </div>
                        <br></br>
                        <div>══════════════════════════════════════════════════════════════════════════════════════════════════════════════</div>
                    </span>
                </div>
                <div className="blockThis typewriter">
                    <br></br>
                    <div>&#128513; I hope you enjoy your stay at Coles Terminal!</div>
                    <div>&#128296; Made using REACT.</div>
                </div>
                <br></br>
            </div>
        )
    }

    // html for the terminal prompt before each message
    function consolePrompt(){
        return (<div className="ConsoleText"> 
            <div className="ConsoleMessage">{consoleMessage}</div>
            <div className="ConsoleDirectory">{consoleDirectory}</div>
        </div>);
    }

    // typical print whenever you enter a command
    function consoleCommandPrint(text){
        return (<div className="ConsoleText" key={Math.random()}> 
            {consolePrompt()}
            <div className="ConsoleTextCommand">{text}</div>
        </div>);
    }

    // prints out when you type an invalid command
    function invalidCommandPrint(command){
        return (<div className="ConsoleText" key={consoleList.length}> 
            {consolePrompt()}
            <div className="ConsoleError">ERROR: Invalid Command</div>
            <div className="ConsoleTextCommand"><span>{" '"}{command}{"' - Try typing '"}</span></div>
            <div className="CommandColor">help</div>
            <div className="ConsoleTextCommand">{"'"} for a list of all commands.</div>
        </div>);
    }

    // prints out the help command
    function helpCommandPrint(){
        return (<div className="ConsoleText" key={consoleList.length}> 
            <div className="ConsoleText">
                <span>
                    <ul className="ConsoleList">
                        <li> <h3>Commands: </h3> </li>
                        <li> <div className="CommandColor Spacer">help</div>- Shows this message.</li>
                        <li> <div className="CommandColor Spacer">pages</div>- Shows a list of all available pages to check out.</li>
                        <li> <div className="CommandColor Spacer">clear</div>- Clears your terminal space.</li>
                        <li> <div className="CommandColor Spacer">banner</div>- Prints out the banner.</li>
                        <li> <h3>Perameter Commands:</h3></li>
                        <li> <div className="CommandColor Spacer">open</div> <div>Usage: open {"<pageName>"}</div> - Will open an inputed page.</li>
                    </ul>
                </span>
            </div>
        </div>);
    }

    // prints out the pages command
    function pagesCommandPrint(){
        return (<div className="ConsoleText" key={consoleList.length}> 
            <div className="ConsoleText">
                <ul className="ConsoleList">
                    <li> <h3>Page Names: </h3> </li>
                    <li>about</li>
                    <li>projects</li>
                    <li>connect</li>
                    <li>resume</li>
                </ul>
            </div>
        </div>);
    }

    function pageNotFoundPrint(page){
        return (<div className="ConsoleText" key={consoleList.length}> 
            {consolePrompt()}
            <div className="ConsoleError">ERROR: Could Not Find Page</div>
            <div className="ConsoleTextCommand"><span>{" '"}{page}{"' - Try typing '"}</span></div>
            <div className="CommandColor">pages</div>
            <div className="ConsoleTextCommand">{"'"} for a list of all pages.</div>
        </div>);
    }

    // checks in a command is valid or not
    function checkCommand(message){
        const messageLower = message.toLowerCase();
        const commandName = messageLower.split(" ")[0];
        if (validCommandsList.includes(commandName)){
            return [true, commandName];
        }
        return [false, commandName];
    }

    // is ran after the user clicks enter on the input
    function addConsoleText(message){
        const [isValid, command] = checkCommand(message);
        setConsoleList(prevConsoleList => prevConsoleList.concat(consoleCommandPrint(message)));

        if (isValid){
            validCommand(command, message);
        }else{
            setConsoleList(prevConsoleList => prevConsoleList.concat(invalidCommandPrint(command)));
        }
    }

    // if a command is valid, this parses the message which determines what command to run. 
    function validCommand(command, message){
        switch(command){
            case "clear":
                setConsoleList([]);
                break;
            case "help":
                setConsoleList(prevConsoleList => prevConsoleList.concat(helpCommandPrint()));
                break;
            case "pages":
                setConsoleList(prevConsoleList => prevConsoleList.concat(pagesCommandPrint()));
                break;
            case "open":
                // decides which page to open 
                const page = message.split(" ")[1];
                switch(page){
                    case "about":
                        setConsoleList(prevConsoleList => prevConsoleList.concat(<About key={consoleList.length}/>));
                        break;
                    case "resume":
                        setConsoleList(prevConsoleList => prevConsoleList.concat(<Resume key={consoleList.length}/>));
                        break;
                    default:
                        setConsoleList(prevConsoleList => prevConsoleList.concat(pageNotFoundPrint(page)));
                }
                break;
            case "banner":
                setConsoleList(prevConsoleList => prevConsoleList.concat(getBanner()));
                break;
            default:
                setConsoleList(prevConsoleList => prevConsoleList.concat(consoleCommandPrint(message)));
        }
    }

    return (
        <div>
            <header>
                <h1 className="ConsoleHeader"> <div className="ConsoleTitle">{consoleMessage}</div>  </h1>
            </header>

            <div className="Console" id="ConsoleID">
                <AnimateHeight 
                style={{flexShrink: 0}}
                duration={1000} 
                height={height}
                onHeightAnimationStart={()=>startInterval()} 
                onHeightAnimationEnd={()=> stopInterval()}>

                    <div className="ConsoleContent" id="ConsoleContentID">
                        {consoleList}
                    </div>
                    
                </AnimateHeight>
                <div className="ConsoleInputWrap">
                    <div className="ConsoleDirector">
                        <div className="ConsoleMessage">{consoleMessage}</div>
                        <div className="ConsoleDirectory">{consoleDirectory}</div>
                    </div>
                    <input
                    type="text" 
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={e=>{
                        if (e.key === "Enter"){
                            addConsoleText(input);
                            setInput("");
                        }
                    }}
                    className="ConsoleInput"></input>
                </div>
            </div>
        </div>
    )
}
export default Console