import './index.css';
import Console from "./components/Console";
// import Footer from "./components/Footer";

function App() {

  return (
    <div>
        <Console/>
    </div>
  );
}

export default App;
