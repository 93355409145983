function Resume(){
    return (
        <div className="page">
            <div className="tabIn">
                <h1 className="">Resume</h1>
                <div className="resumeFlex">

                    <div>
                        <h3>Personal Info</h3>
                        <ul>
                            <li>
                                Name: Cole Monpas
                            </li>
                            <li>
                                Phone: {"(425)"}361-3624 {"\n"}
                            </li>
                            <li>
                                Email: sapnom24@gmail.com {"\n"}
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3>Technical Skills</h3>
                        <p>Languages:</p>
                        <ul>
                            <li>React</li>
                            <li>C</li>
                            <li>Java</li>
                            <li>Python</li>
                            <li>Javascript</li>
                            <li>HTML/CSS</li>
                        </ul>
                        <p>Tools:</p>
                        <ul>
                            <li>Visual Studio Code</li>
                            <li>Git Version Control</li>
                            <li>Unix/Linux</li>
                            <li>Windows</li>
                        </ul>
                    </div>

                    <div>
                        <h3>Education</h3>
                        <p>Western Washington University:</p>
                        <ul>
                            <li>Bachelor's of Science: Computer Science</li>
                            <li>Bellingham Washington</li>
                            <li>Issued June 2022</li>
                        </ul>
                        <p>Signifigant Courses:</p>
                        <ul>
                            <li>Artificial Intelligence</li>
                            <li>Embedded Systems</li>
                            <li>Operating Systems</li>
                        </ul>
                    </div>
                </div>
                <h2 className="subheadResume">Experience / Projects:</h2>
                <div className="resumeFlex">
                    <div>
                        <h3>Embedded Chip Operating System</h3>
                        <h4>- C (LPC1114)</h4>
                        <ul>
                            <li>Created an operating system from scratch for an LPC1114 chip in c.</li>
                            <li>The operating system is capable of multiple threads and handling interrupts.</li>
                            <li>The chip can blink an LED, and to rotate a servo by moving a potentiometer.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Avatar Facial Editor</h3>
                        <h4>- C#, Unity</h4>
                        <ul>
                            <li>Collaborated with a team to produce a system for manipulating facial features on 3-dimensional models.</li>
                            <li>Used blend shapes as the base of the project. Blend shapes allows you to iterate through parts of the body on the 3d model and edit the vertices.</li>
                            <li>Utilized Unity for its gameobject-based UI system which allowed us to create the UI system.</li>
                        </ul>
                    </div>
                    <div>
                        <h3>Secure Chat Application</h3>
                        <h4>- Python, Crypto.Cipher</h4>
                        <ul>
                            <li>Created a chat application using standard cryptographic protocols.</li>
                            <li>Implemented certificate authority authentication for user sign-ins.</li>
                            <li>Used AES block cipher for encryption across client messages.</li>
                            <li>Private messaging feature is encrypted so not even the server can decrypt them.</li>
                        </ul>
                    </div>
                </div>
                <div className="resumeFlex">
                    <div>

                    </div>
                    <div>
                        <h3>Terminal Style Website {" (this)"}</h3>
                        <h4>- React, Javascript, CSS, HTML</h4>
                        <ul>
                            <li>I made a linux terminal styled website in React as my personal website.</li>
                            <li>It makes use of the React-Spring library, and it also uses hooks as the premise and core of the site.</li>
                            <li>Features multiple commands and pages which you can browse yourself.</li>
                        </ul>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
       
    )
}

export default Resume