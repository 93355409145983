import { useState } from "react";
import { useSpring, animated } from "react-spring";


function About(){
    const [open, toggle] = useState(false);

    const expand = useSpring({
      config: { friction: 10, tension: 50},
      width: open ? "100%" : "4%"
    });

    const spin = useSpring({
      config: { friction: 10 },
      transform: open ? "scaleX(-1)" : "scaleX(1)"
    });

    const shake = useSpring({
        config: { friction: 10, tension: 100},
        loop: true,
        to: [
        { transform: "translate(-15px, 0px)"},
        { transform: "translate(-5px, 0px)"},
        { transform: "translate(-15px, 0px)"},
        ],
        from: { transform: "translate(-15px, 0px)"},
    });

    function expandToggle(){
        toggle(!open);
    }


    return (
        <div className="page">
            <div className="tabIn">
                <h1>About</h1>
                <div className="row">
                    <div className="column">
                        <h3>Hey, I'm Cole...</h3>
                        <p>
                            I'm a graduate from Western Washington University. 
                            I've studied programming for 5+ years and I have have my bachelors degree in computer science. 
                            I'm passionate about code optimization and cohesiveness. 
                        </p>
                        <br></br>
                        <h3>I'm on a search...</h3>
                        <p>
                            I'm looking for a great opportunity where I can connect with others who share a similar passion for programming. 
                            I'd love to be part of a creative team who is always striving to better themselves personally and professionally. 
                            I believe a team who has a genuine desire to succeed will beat out other teams who are only there to check off tasks at the end of the day.
                        </p>
                        <br></br>
                        <h3>I've got some passions...</h3>
                        <p>
                            Some passions of mine outside of my education involve eating delicious food, traveling, and spending time with my dogs. 
                            I also just recently started playing frisbee golf in my freetime which I really enjoy -- I'm not very good yet though!
                        </p>
                        <br></br>
                        <h3>Lets get acquainted...</h3>
                        <p>
                            I'm excited to start this next chapter in my life, and I hope for you and your organization to be part of it!
                        </p>
                        <br></br>
                    </div>
                    <div className="column portraitImage">
                        <animated.div className="socialSlide" style={expand}>
                            <div className="socialContent" id="aboutBehindImage"> 
                                
                            </div>
                        </animated.div>
                        <div>
                            <animated.div style={shake}>
                                <animated.button className="socialButton" onClick={()=> expandToggle()} style={spin} > &#8594; </animated.button>
                            </animated.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About